import React from 'react'

import { GetStaticProps } from 'next'

import { PageProps, PageData } from '../@types/Page'
import { PageSettings } from '../@types/PageSettings'

import { getPageData, getPageSettings } from '../utils/api'

import Layout from '../src/layouts/Layout'

import Content from '../src/components/Content'

const Home: React.FC<PageProps> = ({ settings, content }) => {
  return (
    <Layout settings={settings} content={content} invertMenuColors>
      <Content settings={settings} content={content} />
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const settings: PageSettings = await getPageSettings()
  const content: PageData = await getPageData(['/'])

  const data = {
    settings,
    content,
  }

  return {
    props: { ...data },
    revalidate: 10,
  }
}

export default Home
